<template>
  <div class="card" :class="[type, color]" @click="$emit('click')">
    <span v-if="color">{{ color }}</span>
    <template v-if="type === 'ticket'">
      <span class="start">{{ start }}</span>
      to<br />
      <span class="end">{{ end }}</span>
      <span class="points">{{ points }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'StandardDeckCard',
  props: {
    type: String,
    color: String,
    start: String,
    end: String,
    points: Number,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.card {
  cursor: pointer;
  font-weight: bold;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 8px;
  margin-right: 6px;
  margin-bottom: 6px;
  position: relative;
  width: 62px;
  height: 88px;
  vertical-align: middle;
  user-select: none;

  &.selected {
    box-shadow: 0 0 5px 3px #017bbc;
  }
  &.ticket {
    font-size: 13px;
    font-weight: normal;
    padding-bottom: 20px;
    width: 132px;
    height: 73px;

    span {
      font-weight: bold;
      display: block;
    }
  }
}
.white {
  background-color: #fff;
}
.blue {
  color: #fff;
  background-color: #017bbc;
}
.yellow {
  background-color: #f5d656;
}
.purple {
  background-color: #b850e4;
}
.orange {
  background-color: #eb9c08;
}
.black {
  color: #fff;
  background-color: #000;
}
.red {
  background-color: #c73822;
}
.green {
  background-color: #a4c638;
}
.wild {
  background: linear-gradient(
    180deg,
    rgba(199, 56, 34, 1) 0%,
    #eb9c08 20%,
    rgba(245, 214, 86, 1) 40%,
    rgba(164, 198, 56, 1) 60%,
    rgba(1, 123, 188, 1) 80%,
    rgba(184, 80, 228, 1) 100%
  );
}
.points {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  border: 1px solid #000;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
}
</style>
